import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { VisitorEvent } from 'src/app/modules/shared/models/visitor.event';
import { Location } from '@angular/common';
import { GoogleAnalyticsService } from '../google.analytics/google.analytics.service';
import { VisitorPlace } from 'src/app/modules/shared/models/visitor.place';
import { LocalStorageService } from 'src/app/modules/shared/services/local.storage.service';
import { isEmptyArray } from '../../utils/object.extensions';
import { Meta, Title } from '@angular/platform-browser';
import { getAllowedTextForUrl, removeHtmlTags } from '../../utils/string.extensions';
import { Itinerary } from '../../../modules/trip-planner/models/itinerary';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  defaultLogo =
    'https://imgoingcalendar.com/assets/imgs/ImGoingLogo-2018-3.png';

  constructor(
    private _location: Location,
    private _gaService: GoogleAnalyticsService,
    private _meta: Meta,
    private _title: Title
  ) {
  }

  public addNameIdToUrlForNonScriptSolution(
    item: VisitorEvent | VisitorPlace,
    hubName: string,
    dialogRef: MatDialogRef<any>
  ) {
    const type = this.getItemType(item);
    const fromUrl =
      window.location.href.indexOf(`/${type}s/details/`) === -1
        ? window.location.href.split(`/${hubName}/`)[1]
        : null;

    // use setTimeout is because if you view an event from the current event's details page's upcoming events section,
    // the current event popup will close which will set the url back to event calendar
    setTimeout(() => {
      // change location href, so that there can be a specific url for this event details
      this._location.go(`${hubName}/${type}s/details/${item._id}`);

      this._gaService.trackPageView();
    });

    dialogRef.beforeClosed().subscribe(() => {
      if (fromUrl) {
        this._location.go(`${hubName}/${fromUrl}`);
      } else {
        this._location.go(`${hubName}/${type}s`);
      }
      this.removeMetaForSEO();
    });
  }

  public addNameIdToUrlForItinerary(
    itinerary: Itinerary,
    hubName: string,
    dialogRef: MatDialogRef<any>
  ) {
    const nameId = this.getNameId(itinerary);

    const url = new URL(window.location.href);
    url.searchParams.set('ig-itinerary', nameId); // replace with your new value
    window.history.pushState({}, '', url);

    dialogRef.beforeClosed().subscribe(() => {
      const url = new URL(window.location.href);
      url.searchParams.delete('ig-itinerary'); // Remove parameter
      window.history.pushState({}, '', url);
    });
  }

  public addNameIdToUrlForItineraryScriptSolution(
    itinerary: Itinerary,
    hubName: string,
    dialogRef: MatDialogRef<any>
  ) {
    const fromUrl = window.location.href;
    if (fromUrl.includes('ig-itinerary=')) {
      return;
    }
    console.log('fromUrl', fromUrl);
    const nameId = this.getNameId(itinerary);
    const connector = fromUrl.includes('?') ? '&' : '?';

    setTimeout(() => {
      window.history.pushState(null, null, `${fromUrl}${connector}ig-itinerary=${nameId}`);
    });

    // setTimeout(() => {
    //   this._gaService.trackPageView();
    // }, 200);

    dialogRef &&
    dialogRef.beforeClosed().subscribe(() => {
      window.history.pushState(null, null, fromUrl);
    });
  }

  public getIfNeedToAddNameIdToUrl() {
    const wrapper =
      document.getElementById('imgoingcalendar-wrapper') ||
      document.getElementById('imgoingcalendar-wrapper-wix');
    if (wrapper) {
      const nameId =
        wrapper.attributes['url-change'] ||
        wrapper.attributes['data-url-change'];
      return !!nameId;
    }
    return false;
  }

  public addNameIdToUrlForScriptSolution(
    item: VisitorEvent | VisitorPlace,
    dialogRef: MatDialogRef<any>
  ) {
    // const type = this.getItemType(item);
    // let oriHref = window.location.href.split(`imgoing-${type}=`)[0];
    // oriHref =
    //   oriHref.endsWith('&') || oriHref.endsWith('?')
    //     ? oriHref.substring(0, oriHref.length - 1)
    //     : oriHref;

    // let connector = '?';
    // if (window.location.search) {
    //   if (!window.location.search.includes(`?imgoing-${type}=`)) {
    //     connector = '&';
    //   }
    // }
    // const search =
    //   type === 'event'
    //     ? `${connector}imgoing-event=${item.nameId}`
    //     : `${connector}imgoing-place=${item.nameId}`;

    const link = this.generateLink(item);
    console.log('link in seo', link);

    // use setTimeout is because if you view an event from the current event's details page's upcoming events section,
    // the current event popup will close which will set the url back to event calendar
    setTimeout(() => {
      window.history.pushState(null, null, link.link);
    });

    setTimeout(() => {
      this._gaService.trackPageView();
    }, 200);

    dialogRef &&
    dialogRef.beforeClosed().subscribe(() => {
      this.removeMetaForSEO();
      window.history.pushState(null, null, link.originHref);
    });
  }

  public generateLink(item: VisitorEvent | VisitorPlace): {originHref; link} {
    const type = this.getItemType(item);

    let oriHref = window.location.href.split(`imgoing-${type}=`)[0];
    oriHref =
      oriHref.endsWith('&') || oriHref.endsWith('?')
        ? oriHref.substring(0, oriHref.length - 1)
        : oriHref;

    let connector = '?';
    if (window.location.search) {
      if (!window.location.search.includes(`?imgoing-${type}=`)) {
        connector = '&';
      }
    }

    const search =
      type === 'event'
        ? `${connector}imgoing-event=${item.nameId}`
        : `${connector}imgoing-place=${item.nameId}`;

    return {
      originHref: oriHref,
      link: oriHref + search,
    };
  }

  public createAndInjectGoogleStructureDataScriptForEvent(event: VisitorEvent) {
    this.removeExistingScript();
    if (!event.isOnlineEvent) {
      const data = this.getStructuredDataForEvent(event);
      this.injectGoogleStructureDataScript(data);
    }
  }

  public createAndInjectGoogleStructureDataScriptForPlace(place: VisitorPlace) {
    this.removeExistingScript();
    const data = this.getStructuredDataForPlace(place);
    this.injectGoogleStructureDataScript(data);
  }

  public addMetaForSEO(title: string, description: string) {
    // this._meta.removeTag("property='description'");
    this._meta.removeTag("name='description'");
    this._meta.removeTag("content='noindex'");
    description &&
    this._meta.addTag({
      name: 'description',
      content: removeHtmlTags(description),
    });

    if (!this._title.getTitle().includes(title)) {
      this._title.setTitle(this._title.getTitle() + ' - ' + title);
    }

    // update: remove canonical link in imgoingscript.js for event/place listing page, and add for details page
    // this.removeCanonical();
  }

  public removeCanonical() {
    setTimeout(() => {
      // remove canonical link, as it may cause Google won't index event/place details page
      // <link rel="canonical" href="https://savannahvirtualvisitorcenter.com/events/" />
      const canonicalLink = document.querySelector('link[rel="canonical"]');
      console.log('canonicalLink', canonicalLink);
      canonicalLink && canonicalLink.remove();
    });
  }

  public removeMetaForSEO() {
    this._meta.removeTag("name='description'");
    this._meta.removeTag("content='noindex'");
    const title = this._title.getTitle().split(' - ').slice(0, -1).join(' - ');
    this._title.setTitle(title);
  }

  public createNoIndexMeta() {
    // use setTimeout to avoid the noindex meta tag being removed by the modal close function
    // as for non exist event, the removeMetaForSEO will be triggered when the modal is closed
    setTimeout(() => {
      // this event/place does not exist, so this is should not be indexed by search engine.
      const metaRobots = document.createElement('meta');
      metaRobots.name = 'robots';
      metaRobots.content = 'noindex';
      document.head.appendChild(metaRobots);
    }, 100);
  }

  private removeMeta(property: string): void {
    const meta = document.querySelector(`meta[property="${property}"]`);
    meta && meta.parentNode.removeChild(meta);
  }

  private getMetaContent(property: string): string {
    const meta = document.querySelector(`meta[property="${property}"]`);
    return meta && meta.getAttribute('content');
  }

  private removeExistingScript() {
    const deleteJs = document.getElementById('google-search-structured-data');
    deleteJs && deleteJs.parentNode.removeChild(deleteJs);
  }

  private injectGoogleStructureDataScript(data: {}) {
    const script = document.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.setAttribute('id', 'google-search-structured-data');
    script.textContent = JSON.stringify(data);
    document.head.appendChild(script);
  }

  public getNameId(x: VisitorPlace | VisitorEvent | Itinerary) {
    let name = getAllowedTextForUrl(x['name'] || x.title);
    return `${name.replace(/( )+/g, '-').toLowerCase()}-${x._id}`;
  }

  private getStructuredDataForEvent(event: VisitorEvent) {
    return {
      '@context': 'https://schema.org',
      '@type': 'Event',
      image: [
        `${
          event.cover
            ? event.cover.source || this.defaultLogo
            : this.defaultLogo
        }`,
      ],
      '@id': `${event._id}`,
      name: `${event.name || event.title}`,
      startDate: `${event.startTime}`,
      endDate: `${event.endTime}`,
      eventAttendanceMode: `https://schema.org/${
        event.isOnlineEvent
          ? 'OnlineEventAttendanceMode'
          : 'OfflineEventAttendanceMode'
      }`,
      eventStatus: `https://schema.org/${
        event.isOnlineEvent ? 'EventMovedOnline' : 'EventScheduled'
      }`,
      location: {
        '@type': `${event.isOnlineEvent ? 'VirtualLocation' : 'Place'}`,
        // "name": "Snickerpark Stadium",
        address: {
          '@type': 'PostalAddress',
          streetAddress: `${event.address?.address || event?.address}`,
          // "addressLocality": "Snickertown",
          // "postalCode": "19019",
          // "addressRegion": "PA",
          addressCountry: 'US',
        },
        url: `${event.eventLink}`,
      },
      description: event.description,
      organizer: {
        '@type': 'Organization',
        name: event.owner?.name,
        url: event.hostLink,
      },
    };
  }

  private getStructuredDataForPlace(place: VisitorPlace) {
    return {
      '@context': 'https://schema.org',
      '@type': 'Store',
      image: [place.cover?.source || this.defaultLogo],
      name: place.name,
      address: {
        '@type': 'PostalAddress',
        streetAddress: place.address.address,
        // addressLocality: 'San Jose',
        // addressRegion: 'CA',
        // postalCode: '95129',
        addressCountry: 'US',
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: place.address.lat,
        longitude: place.address.lng,
      },
      url: place.website,
      priceRange: place.priceRange,
      telephone: place.phone,
      // openingHoursSpecification: [
      //   {
      //     '@type': 'OpeningHoursSpecification',
      //     dayOfWeek: [
      //       'Monday',
      //       'Tuesday',
      //       'Wednesday',
      //       'Thursday',
      //       'Friday',
      //       'Saturday',
      //     ],
      //     opens: '08:00',
      //     closes: '23:59',
      //   },
      //   {
      //     '@type': 'OpeningHoursSpecification',
      //     dayOfWeek: 'Sunday',
      //     opens: '08:00',
      //     closes: '23:00',
      //   },
      // ],
    };
  }

  private getItemType(item) {
    return item.startTime ? 'event' : 'place';
  }
}
