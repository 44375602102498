import { AfterViewInit, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { concatMap, from, Observable, of, switchMap, tap } from 'rxjs';
import { ImageService } from '../../services/image/image.service';
import { ImageSelectorComponent } from '../image.selector/image.selector.component';

@Component({
  selector: 'ig-image-link-file-selector',
  templateUrl: './image.link.file.selector.component.html',
  styleUrls: ['./image.link.file.selector.component.scss'],
  host: {
    class: 'ig-image-link-file-selector',
  },
  encapsulation: ViewEncapsulation.None
})
export class ImageLinkFileSelectorComponent implements AfterViewInit {
  form: FormGroup;
  hasCopyrights: boolean = false;

  @Input() compressWidth = 1200;

  @ViewChild('imgSelector', {static: true})
  imgSelector: ImageSelectorComponent;

  constructor(public imgService: ImageService, private _fb: FormBuilder) {
    this.form = this._fb.group({
      imageLink: [null, []],
      imgFile: [null, []],
    });
  }

  ngAfterViewInit(): void {
    this.checkUsingImgLinkOrFile();
  }

  async imgSelected(imgFile: File) {
    this.form.get('imgFile').setValue(imgFile);
  }

  public get isUsingLink() {
    return !!this.form.get('imageLink').value;
  }

  public get isUsingFile() {
    return !!this.form.get('imgFile').value;
  }

  public get isValid() {
    return (
      (this.isEmpty()) ||
      ((this.isUsingLink || this.isUsingFile) && this.hasCopyrights)
    );
  }

  public isEmpty() {
    return !this.isUsingLink && !this.isUsingFile;
  }

  public uploadImg(): Observable<{success: boolean; url: string}> {
    console.log('uploadImg', this.isUsingFile);
    if (!this.isValid || this.isEmpty()) return of({success: false, url: null});

    return of(this.form.get('imageLink').value).pipe(
      concatMap((x) => {
        return this.isUsingFile
          ? from(
            this.imgService.compressImageFileToBase64(
              this.form.get('imgFile').value, this.compressWidth
            )
          )
          : of(x);
      }),
      switchMap((x) => {
        return x
          ? this.imgService.uploadBase64ImageToAWS(x, this.compressWidth)
          : of({success: true, url: null});
      }),
      tap((x) => {
        if (!x.success)
          throw Error(
            'Upload image to AWS error, please check your image link or file!'
          );
      })
    );
  }

  public clear() {
    this.form.get('imageLink').setValue(null);
    this.form.get('imgFile').setValue(null);
    this.imgSelector?.clear();
    this.hasCopyrights = false;
  }

  private checkUsingImgLinkOrFile() {
    this.form
      .get('imageLink')
      .valueChanges.pipe(
      tap((value) => {
        value && this.imgSelector?.clear();
        value && this.form.get('imgFile').setValue(null);
      })
    )
      .subscribe();

    this.form
      .get('imgFile')
      .valueChanges.pipe(
      tap((value) => {
        value && this.form.get('imageLink').setValue(null);
      })
    )
      .subscribe();
  }
}
