import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EventTimeService } from './event.time.service';
import { ApiService } from '../../../common/services/api/api.service';
import { inject, Injectable } from '@angular/core';
import { Observable, map, of, switchMap } from 'rxjs';
import { CalendarQueryCommand } from '../../shared/models/calendar.action.command';
import {
  clone,
  isEmptyArray,
  isNullOrUndefined,
  isObject,
  upperFirstLetter,
} from 'src/app/common/utils/object.extensions';
import {
  GooglePlaceReview,
  PlaceAdditionalData,
  VisitorPlace,
} from '../../shared/models/visitor.place';
import { CreatePlaceCommand } from '../../shared/models/create.place.command';
import { PlaceType } from '../../shared/models/place.type';
import { VisitorEvent } from '../../shared/models/visitor.event';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/common/services/config/config.service';
import { ActivatedRoute } from '@angular/router';
import { IAppState, IFrontendSettings } from '../../shared/ngrx.stores/frontend.settings/states';
import * as PlaceQueryCommandActions from '../../place-calendar/ngrx.stores/place.query.command/actions';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { VisitorService } from '../../shared/services/visitor.service';

@Injectable({
  providedIn: 'root',
})
export class VisitorPlaceService {

  constructor(
    public sanitizer: DomSanitizer,
    public visitorService: VisitorService,
    private _apiService: ApiService,
    private _eventTimeService: EventTimeService,
    private _store: Store<IAppState>,
    protected http: HttpClient,
    private _location: Location,
  ) {
  }

  getPlaces(
    hubName: string,
    paramObj: CalendarQueryCommand
  ): Observable<VisitorPlace[]> {
    return this._apiService.get(`visitors/${hubName}/places`, paramObj).pipe(
      map((places) => {
        this.processPlaceData(places);
        return places;
      })
    );
  }

  getLocationFilter(hubName: string): Observable<{locality: string}[]> {
    return this._apiService.get(`visitors/${hubName}/places/location-filter`);
  }

  getGooglePlaceDetails(
    placeId: string,
    hubName: string
  ): Observable<VisitorPlace> {
    return this._apiService
      .get(
        `visitors/${hubName}/places/google-place-details?placeId=${placeId}&convertImgUrl=true`
      )
      .pipe(
        map((place) => {
          this.processPlaceData([place]);
          return place;
        })
      );
  }

  getGooglePlaceReviews(
    placeId: string,
    sort: string
  ): Observable<GooglePlaceReview[]> {
    return this._apiService.get(
      `common/google-reviews?placeId=${placeId}&sort=${sort}`
    );
  }

  getNearByPlaces(
    targetPlace: VisitorPlace,
    range: number,
    category?: string,
    categoryList?: string[]
  ): Observable<VisitorPlace[]> {
    return this._apiService
      .post(`visitors/${targetPlace.hubName}/places/nearby-places`, {
        targetPlace,
        range: range * 1609,
        limit: 100,
        category,
        categoryList: isEmptyArray(categoryList) ? null : categoryList,
      })
      .pipe(
        switchMap((data) => {
          this.processPlaceData(data);
          return of(data as VisitorPlace[]);
        })
      );
  }

  getNearByEvents(
    targetPlace: VisitorPlace,
    radius: number
  ): Observable<VisitorEvent[]> {
    return this._apiService
      .get(
        `visitors/${targetPlace.hubName}/events/nearby-events?lat=${targetPlace.address.lat}&lng=${targetPlace.address.lng}&radius=${radius}`
      )
      .pipe(
        map((events) => {
          events.forEach((event) => {
            this._eventTimeService.processEventTimes(event);
          });
          return events;
        })
      );
  }

  getGoogleImgUrl(photoReference: string, googleAppKey: string): string {
    // for new Google place API
    if (photoReference.startsWith('places/')) {
      return ConfigService.config.google.placeImage.replace('${reference}', photoReference).replace('${key}', googleAppKey);
    }

    return ConfigService.config.google.placePhoto
      .replace('${photo_reference}', photoReference)
      .replace('${appKey}', googleAppKey);
  }

  createPlace(hubName, command: CreatePlaceCommand) {
    const categoryCode = command.category
      .split(', ')
      .map((x) => {
        return this.toCategoryCode(x);
      })
      .join(', ');
    command.category = categoryCode;
    return this._apiService.post(`visitors/${hubName}/places`, command);
  }

  public buildFilterPlacesByCustomCategoryCommandFromUrlQuery(
    activatedRoute: ActivatedRoute, settings: IFrontendSettings
  ): Observable<CalendarQueryCommand> {
    return activatedRoute.queryParams.pipe(
      switchMap((params) => {
        let customPlaceTypeId = "";
        if (window.location.search.includes('ig-custom-places')) {
          const searchParams = new URLSearchParams(window.location.search.split('?')[1]);
          console.log('searchParams for ig-custom-places', searchParams);
          for (const [key, value] of searchParams) {
            if (key === 'ig-custom-places') {
              if (value.includes('-')) {
                customPlaceTypeId = value.split('-').last();
              } else {
                customPlaceTypeId = value;
              }
            }
          }
          console.log(`customEvents in custom place category: ${customPlaceTypeId}`);
        }

        if (!customPlaceTypeId) {
          return of(null);
        }

        const command = new CalendarQueryCommand();

        console.log('settings when buildFilterPlacesByCustomCategoryCommandFromUrlQuery', settings);

        if (settings) {
          let category = this.findCategoryById(
            customPlaceTypeId,
            settings.customSettings.customDefaultPlaceCategory
              .customCategories
          );
          if (category) {
            // return to the specified node in URL (only render this node in the dropdown)
            let categoryList = [];
            this.getAllSubCategories(category, categoryList);
            console.log('categoryList load from url', categoryList);
            command.categoryList = categoryList;
            command.categoryNode = category;

            const currentPath = this._location.path();
            if (currentPath.includes('ig-custom-places=')) {
              this._location.go(currentPath.split('ig-custom-places=')[0] + `ig-custom-places=${this.visitorService.convertToUrlSafeString(category.title)}-${category._id}`);
            } else {
              const connector = currentPath.includes('?') ? '&' : '?';
              this._location.go(currentPath + `${connector}ig-custom-places=${this.visitorService.convertToUrlSafeString(category.title)}-${category._id}`);
            }
            return of(command);
          }

          // if the id is not custom category, then try to find it in favorite custom places
          const favCustomPlace = settings.customSettings.customPlaces.find(x => x._id.toString() === customPlaceTypeId);
          console.log('favCustomPlace load from url', favCustomPlace);
          if (favCustomPlace) {
            this._store.dispatch(
              PlaceQueryCommandActions.setFavoritePlaceBtnId({
                favBtnId: customPlaceTypeId,
              })
            );
          }
        }

        return of(null);
      })
    );
  }

  getDisplayCategory(place: VisitorPlace): string {
    if (place?.categoryList || place?.category) {
      return (place.categoryList || place.category.split(', '))
        .map((x) => {
          return this.toReadableCategory(x);
        })
        .join(', ')
        .replace(', Point of Interest', '')
        .replace(', Point Of Interest', '')
        .replace(', Establishment', '');
    }

    return '';
  }

  /**
   * tourist_attraction  => Tourist Attraction
   * b&b => B&B
   * arts_&_culture => Arts & Culture
   * */
  toReadableCategory(category: string) {
    return category
      .trim()
      .replaceAll('&', '_&_')
      .split('_')
      .map((x) => {
        return upperFirstLetter(x);
      })
      .join(' ')
      .replaceAll(' & ', '&');
  }

  /**
   *  Tourist Attraction => tourist_attraction
   * */
  toCategoryCode(category: string) {
    return category.toLowerCase().trim().replaceAll(' ', '_');
  }

  // recursively find category
  findCategoryById(id: string, allCategories: PlaceType[]): PlaceType | null {
    for (const category of allCategories) {
      if (category._id.toString() === id) {
        return category;
      } else {
        for (const iterator of category.subCategories) {
          const result = this.findCategory(id, iterator);
          if (result) return result;
        }
      }
    }
    return null;
  }

  findCategory(id: string, currentNode: PlaceType): PlaceType | null {
    let i, currentChild, result;
    if (id == currentNode._id.toString()) {
      return currentNode;
    } else {
      for (i = 0; i < currentNode.subCategories.length; i += 1) {
        currentChild = currentNode.subCategories[i];
        result = this.findCategory(id, currentChild);
        if (result) {
          return result;
        }
      }
      return null;
    }
  }

  getAllSubCategories(item: PlaceType, categoryList: string[]) {
    if (item.subCategories && item.subCategories.length) {
      categoryList.push(...item.categoryList);
      for (let subCategory of item.subCategories) {
        this.getAllSubCategories(subCategory, categoryList);
      }
    } else {
      categoryList.push(...item.categoryList);
    }
  }

  checkIsAccommodation(place: VisitorPlace, categories) {
    return categories.any((x) =>
      place.categoryList.any((y) => x.toLowerCase() === y.toLowerCase())
    );
  }

  /**
   * Backend API can recognize place categories (ImGoing default categories) in plain text format
   * However frontend URL contains categories in encoded format
   * This function converts encoded categories to plain text before sending to API
   */
  getDefaultCategoryName(category: string): string {
    if (category === 'accommodation') return 'Accommodations';
    if (category === 'dining-nightlife') return 'Dining & Nightlife';
    if (category === 'activities') return 'Activities';
    if (category === 'shopping') return 'Shopping';
    if (category === 'arts-culture') return 'Arts & Culture';
    if (category === 'outdoors-fitness') return 'Outdoors & Fitness';
    if (category === 'entertainment') return 'Entertainment';
    if (category === 'transportation') return 'Travel';
    if (category === 'other') return 'Other';

    if (category === 'beauty') return 'Beauty';
    if (category === 'cars-motor-services') return 'Cars & Motor Services';
    if (category === 'cleaning-services') return 'Cleaning Services';
    if (category === 'pet-services') return 'Pet Services';
    if (category === 'convenience-pharmacies')
      return 'Convenience & Pharmacies';
    if (category === 'other-types') return 'Other Types';
    if (category === 'trip-tours-planner') return 'Trip & Tours Planner';
    if (category === 'local-rental-services') return 'Local Rental Services';
    if (category === 'health-personal-care') return 'Health & Personal Care';
    if (category === 'sports-leisure') return 'Sports & Leisure';
    if (category === 'art-culture') return 'Art and Culture';
    if (category === 'food-drink') return 'Food & Drink';
    if (category === 'food-and-drink') return 'Food and Drink';
    if (category === 'nightlife') return 'Nightlife';

    return category;
  }

  getLocationFeeds(
    hubName: string
  ): Observable<{title: string; socialName: string}[]> {
    return this._apiService.get(`hubs/${hubName}/location-feeds`);
  }

  getDestinationPlaceFeeds(
    hubName: string
  ): Observable<any> {
    return this._apiService.get(
      `visitors/${hubName}/places/places-for-travel-buddy`
    );
  }

  // Youtube video link looks like:
  // https://www.youtube.com/watch?v=puKsjFRwIyw
  // https://www.youtube.com/watch?v=puKsjFRwIyw&t=27s
  getYoutubeLink(place: VisitorPlace): SafeResourceUrl {
    if (place.youtubeLink && place.youtubeLink.includes('watch?v=')) {
      const code = place.youtubeLink.split('watch?v=')[1].split('&')[0];
      const youtubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        ConfigService.config.youtubeUrl.videoLink.replace('${code}', code)
      );
      return youtubeUrl;
    }
    return null;
  }

  get360VideoLink(place: VisitorPlace): SafeResourceUrl {
    if (place._360VideoKey) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        ConfigService.config._360VideoUrl.replace('${id}', place._360VideoKey)
      );
    }
    return null;
  }

  getRatingImg(rating: number) {
    // https://iti-images.s3.amazonaws.com/imgs/rating-5.png
    if (Number.isInteger(rating)) {
      return `https://iti-images.s3.amazonaws.com/imgs/rating-${rating}.png`;
    }

    return `https://iti-images.s3.amazonaws.com/imgs/rating-${Math.floor(
      rating
    )}.5.png`;
  }

  private processPlaceData(data) {
    data.forEach((item) => {
      if (!isNullOrUndefined(item.lat) && !isNullOrUndefined(item.lng)) {
        let address = item.address;
        item.address = {
          address,
          lat: item.lat,
          lng: item.lng,
          addressComponents: item.address_components,
        };
      }
      if (item.priceRange) {
        item.priceRange = this.getPriceRangeSymbol(item.priceRange);
      }
      if (item.hours) {
        item.hoursText = [
          'Monday: ',
          'Tuesday: ',
          'Wednesday: ',
          'Thursday: ',
          'Friday: ',
          'Saturday: ',
          'Sunday: ',
        ];
        if (isObject(item.hours)) {
          this.setPlaceHourForObject(item);
        } else if (Array.isArray(item.hours)) {
          item.hours.forEach((x) => {
            this.setPlaceHourText(x.key, item, x);
          });
        }
      }
    });
  }

  private setPlaceHourForObject(item) {
    item.hours.mon_1_open && (item.hoursText[0] += item.hours.mon_1_open);
    item.hours.mon_1_close &&
    (item.hoursText[0] += ` - ${item.hours.mon_1_close}`);
    item.hours.tue_1_open && (item.hoursText[1] += item.hours.tue_1_open);
    item.hours.tue_1_close &&
    (item.hoursText[1] += ` - ${item.hours.tue_1_close}`);
    item.hours.wed_1_open && (item.hoursText[2] += item.hours.wed_1_open);
    item.hours.wed_1_close &&
    (item.hoursText[2] += ` - ${item.hours.wed_1_close}`);
    item.hours.thu_1_open && (item.hoursText[3] += item.hours.thu_1_open);
    item.hours.thu_1_close &&
    (item.hoursText[3] += ` - ${item.hours.thu_1_close}`);
    item.hours.fri_1_open && (item.hoursText[4] += item.hours.fri_1_open);
    item.hours.fri_1_close &&
    (item.hoursText[4] += ` - ${item.hours.fri_1_close}`);
    item.hours.sat_1_open && (item.hoursText[5] += item.hours.sat_1_open);
    item.hours.sat_1_close &&
    (item.hoursText[5] += ` - ${item.hours.sat_1_close}`);
    item.hours.sun_1_open && (item.hoursText[6] += item.hours.sun_1_open);
    item.hours.sun_1_close &&
    (item.hoursText[6] += ` - ${item.hours.sun_1_close}`);
  }

  private setPlaceHourText(key, item, value) {
    switch (key) {
      case 'mon_1_open':
        item.hoursText[0] += value;
        break;
      case 'mon_1_close':
        item.hoursText[0] += ` - ${value}`;
        break;
      case 'tue_1_open':
        item.hoursText[1] += value;
        break;
      case 'tue_1_close':
        item.hoursText[1] += ` - ${value}`;
        break;
      case 'wed_1_open':
        item.hoursText[2] += value;
        break;
      case 'wed_1_close':
        item.hoursText[2] += ` - ${value}`;
        break;
      case 'thu_1_open':
        item.hoursText[3] += value;
        break;
      case 'thu_1_close':
        item.hoursText[3] += ` - ${value}`;
        break;
      case 'fri_1_open':
        item.hoursText[4] += value;
        break;
      case 'fri_1_close':
        item.hoursText[4] += ` - ${value}`;
        break;
      case 'sat_1_open':
        item.hoursText[5] += value;
        break;
      case 'sat_1_close':
        item.hoursText[5] += ` - ${value}`;
        break;
      case 'sun_1_open':
        item.hoursText[6] += value;
        break;
      case 'sun_1_close':
        item.hoursText[6] += ` - ${value}`;
        break;
    }
  }

  private getPriceRangeSymbol(price: string | number): string {
    if (!price) return '';

    if (price.toString().startsWith('$')) return price.toString();

    let array: string[] = [];
    for (let i = 0; i < +price; i++) {
      array.push('$');
    }
    return array.join('');
  }
}
