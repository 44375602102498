<div *ngIf="message" class="flex gap-2 w-full text-white p-5">
  <div *ngIf="message.type!=='USER'" class="w-full flex gap-2">
    <ig-image [src]="travelBuddySettings.ui.avatar.thumbnail"
              cls="!w-12 !h-12 min-w-[48px] min-h-[48px] rounded-full"></ig-image>
    <div class="flex flex-col gap-2 w-[calc(100%-48px)]">
      <div class="flex flex-col gap-1">
        <div class="ig-ai-typing text-xs text-gray-400 font-extralight">
          {{ message.name }} {{ message.type === 'AI_THINKING' ? 'is typing...' : '' }}
        </div>
      </div>
      <div class="flex gap-2 relative">
        <div
          class="ig-im-answer max-w-[360px] w-full py-2 px-3 bg-[#f2f2f2] text-sm text-gray-600 rounded-b-lg rounded-tr-lg transition-all"
          [class.loading-scale]="message.type==='AI_THINKING'"
          [class.!w-[50%]]="isScreenExpanded && hasAddingContent()" [class.!max-w-[800px]]="isScreenExpanded"
          [class.max-h-[60vh]]="isScreenExpanded" [class.overflow-y-auto]="isScreenExpanded">

          <ig-loading-dot *ngIf="message.type==='AI_THINKING'"
                          [loadingTips]="loadingTips">
          </ig-loading-dot>

          <div *ngIf="message.type==='AI'||message.type==='AI_STREAM'" class="overflow-x-hidden">
            <div [innerHTML]="message.msg" class=""></div>

            <div #addingContent class="overflow-hidden" [class.ig-im-adding-expanded]="isScreenExpanded"
                 [class.!w-0]="!hasAddingContent()" [class.!h-0]="!hasAddingContent()">
              <ng-content select="[adding]"></ng-content>
            </div>

            <div *ngIf="message?.questions?.length" class="border-t border-gray-300 mt-2 px-3 pt-1">
              <div class="text-xs">
                <!-- <div class="mb-1">Questions you might want to ask</div> -->
                <div *ngFor="let q of message?.questions">
                  <div (click)="askQ(q)"
                       class=" text-gray-600 rounded-3xl py-1 flex items-center justify-between cursor-pointer">
                    <div class=" font-extralight w-[85%]">{{ q }}</div>
                    <mat-icon class="leading-6 text-right">chevron_right</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="message.type==='AI_ERROR'" class="overflow-x-hidden">
            <mat-icon
              class="text-red-500 relative top-[1px] !w-[21px] !h-[15px] !leading-4 text-base">error
            </mat-icon>
            <span [innerText]="errorMsg"></span>
            <span>Please <a (click)="tryAskAgain()">try again</a> later.</span>
          </div>
        </div>


        <!-- <div #addingContentExpanded class="ig-im-expanded-area" [class.w-[50%]]="isScreenExpanded"
          [class.h-[60vh]]="isScreenExpanded && hasAddingContent()">
        </div> -->

      </div>
    </div>
    <!-- <div *ngIf="message?.questions?.length" class="text-xs">
      <div class="mb-1">Questions you might want to ask</div>
      <div *ngFor="let q of message?.questions">
        <div (click)="askQ(q)"
          class="border-2 border-[lightgray] text-gray-600 rounded-3xl px-4 py-1 flex items-center justify-between cursor-pointer max-w-[490px] mb-1">
          <div class=" font-extralight w-[85%]">{{q}}</div>
          <mat-icon class="text-[24px] leading-6">trending_flat</mat-icon>
        </div>
      </div>
    </div> -->
  </div>

  <div *ngIf="message.type==='USER'" class="w-full flex justify-end gap-2">
    <div class="flex flex-col gap-1 text-gray-600 w-full items-end">

      <div class="text-xs font-extralight text-right text-gray-400">{{ message.name }}</div>
      <div class="max-w-[360px] px-3 py-2 bg-[#e5eeff] text-sm rounded-b-lg rounded-tl-lg"
           [class.!max-w-[700px]]="isScreenExpanded" [innerHTML]="message.msg">
      </div>
    </div>

    @if (!letterAvatar) {
      @if (isAllowClick) {
        <div class="relative">
          <ig-image [src]="avatar||defaultAvatar" cls="!w-12 rounded-full aspect-square"></ig-image>
          <div class="absolute top-0 right-0 w-12 h-12 z-10 bg-transparent cursor-pointer"
               [matMenuTriggerFor]="actionMenu">
          </div>
        </div>
      } @else {
        <ig-image [src]="avatar||defaultAvatar" cls="!w-12 rounded-full aspect-square"></ig-image>
      }

    } @else {
      <div
        class="w-10 h-10 min-w-[40px] min-h-[40px] aspect-square rounded-full bg-blue-200 text-xl font-semibold flex justify-center items-center text-blue-500 uppercase cursor-pointer"
        [matMenuTriggerFor]="actionMenu">
        <span>{{ letterAvatar.split('')[0] }}</span>
      </div>
    }


    <mat-menu #actionMenu="matMenu">
      <button mat-menu-item (click)="avatarClicked.emit()">Logout</button>
    </mat-menu>
  </div>

</div>
