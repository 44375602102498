<div class="relative w-full h-full aspect-[5/3]">
  @if (images.length === 1) {
    <ig-image #topImage class="block absolute z-40" [src]="images[topIndex]" cls="aspect-[5/3] rounded-md">
    </ig-image>
  }
  @if (images.length >= 2) {
    <ig-image #topImage class="block absolute z-40" [src]="images[topIndex]" cls="aspect-[5/3] rounded-md">
    </ig-image>

    <ig-image #bottomImage class="block absolute z-30" [src]="images[bottomIndex]" cls="aspect-[5/3] rounded-md">
    </ig-image>
  }
</div>
