import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';

@Component({
  selector: 'ig-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})
export class FieldComponent implements AfterViewInit {
  @Input() mode: 'input' | 'textarea' = 'input';
  @Input() outline: 'outline' | 'fill' = 'outline';
  @Input() label: string = 'input label';
  @Input() icon: string = '';
  @Input() hint: string = '';
  @Input() cls: string = '';
  @Input() isReadOnly: boolean = false;
  @Input() control: FormControl;
  @Input() maxlength = 6000;

  @ViewChild('field', {static: true}) field: MatFormField;
  @ViewChild('input', {static: false}) input: ElementRef;
  @Output() onIconClick: EventEmitter<null> = new EventEmitter();
  @Output() onInputFocus: EventEmitter<any> = new EventEmitter();
  @Output() onInputChange: EventEmitter<any> = new EventEmitter();

  iconClick() {
    this.onIconClick.emit();
  }

  onClick($event) {
    this.onInputFocus.emit($event);
  }

  onChange($event) {
    this.onInputChange.emit($event);
  }

  onFocus($event) {
    if (this.outline !== 'outline') return;
    if (this.control.value) return;
    // solve the outline mode label not wide enough when focusing
    const label = this.field._elementRef.nativeElement.querySelector('label');
    const rect = label.getBoundingClientRect();

    setTimeout(() => {
      const notch = this.field._elementRef.nativeElement.querySelector(
        '.mdc-notched-outline__notch'
      );

      notch.style.width =
        Math.max(50, rect.width) + (this.mode === 'textarea' ? 5 : 0) + 'px';
    });
  }

  ngAfterViewInit(): void {
  }
}
