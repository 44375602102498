import { Observable, of } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { ConfigService } from './../config/config.service';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(
    private _domSanitizer: DomSanitizer,
    private _apiService: ApiService
  ) {
  }

  public safeImage(url: string): SafeUrl {
    return this._domSanitizer.bypassSecurityTrustUrl(url);
  }

  public imageSourceUrl(imageUrl: string, useLogoIfNoImage = true) {
    if (!imageUrl) {
      if (useLogoIfNoImage) {
        return this.defaultImageUrl();
      }
      return this.defaultImageUrl();
    }

    if (imageUrl.startsWith('data:')) {
      return imageUrl;
    }

    if (imageUrl.startsWith('assets/imgs/')) {
      return environment.serverDomain + imageUrl;
    }

    if (!imageUrl.startsWith('http')) {
      // 'https://api.imgoingcalendar.com/imgs/' + imageUrl;
      return environment.imageServeUrl + imageUrl;
    }

    return imageUrl;
  }

  public defaultImageUrl() {
    return 'https://iti-images.s3.amazonaws.com/imgs/new_placeholder_image.jpg'; // environment.serverDomain + ConfigService.config.defaultImageUrl;
  }

  public uploadBase64ImageToAWS(
    imgBase64: string,
    maxWidth?: number | undefined
  ): Observable<{success: boolean; url: string}> {
    return this._apiService.post(`common/upload-img-to-aws`, {
      imgBase64,
      maxWidth,
    });
  }

  public async compressImageFile(file: File, width = 1200): Promise<File> {
    const base64 = await this.compressImageFileToBase64(file, width);
    let ext = 'jpg';
    if (file.name.indexOf('.') >= 0 && file.name.indexOf('.jpg') < 0) {
      ext = file.name.split('.')[1];
    }
    let imgFile = this.blobToFile(this.dataURLtoBlob(base64), `image.${ext}`);
    return imgFile;
  }

  public getImageFileSize(file: File): Promise<{width: number, height: number, size: number}> {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject(null);
      }
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result.toString();

        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          resolve({
            width,
            height,
            size: file.size
          });
        };
      };

      reader.readAsDataURL(file);

      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  public compressImageFileToBase64(file: File, width = 1200): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject(null);
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        let image = new Image();
        image.src = (e.target as any).result;
        image.onload = () => {
          let canvas = document.createElement('canvas'),
            context = canvas.getContext('2d'),
            data = '';

          let targetSize = this.compressImage(image, width);
          canvas.width = targetSize.targetWidth;
          canvas.height = targetSize.targetHeight;

          context.drawImage(
            image,
            0,
            0,
            targetSize.targetWidth,
            targetSize.targetHeight
          );
          let type = 'image/jpeg',
            ext = 'jpg';
          if (file.name.indexOf('.') >= 0 && file.name.indexOf('.jpg') < 0) {
            ext = file.name.split('.')[1];
            type = `image/${ext}`;
          }
          data = canvas.toDataURL(type);
          resolve(data);
        };
      };
    });
  }

  // public compressImgFileToBase64(file: File, width = 800) {
  //   if (!file) {
  //     return of(null);
  //   }

  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = (e) => {
  //     let image = new Image();
  //     image.src = (e.target as any).result;
  //     image.onload = () => {
  //       let canvas = document.createElement('canvas'),
  //         context = canvas.getContext('2d'),
  //         data = '';

  //       let targetSize = this.compressImage(image, width);
  //       canvas.width = targetSize.targetWidth;
  //       canvas.height = targetSize.targetHeight;

  //       context.drawImage(
  //         image,
  //         0,
  //         0,
  //         targetSize.targetWidth,
  //         targetSize.targetHeight
  //       );
  //       let type = 'image/jpeg',
  //         ext = 'jpg';
  //       if (file.name.indexOf('.') >= 0 && file.name.indexOf('.jpg') < 0) {
  //         ext = file.name.split('.')[1];
  //         type = `image/${ext}`;
  //       }
  //       data = canvas.toDataURL(type);
  //       resolve(data);
  //     };
  // }

  public getBase64ImageByUrl(imgUrl): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!imgUrl) {
        resolve(null);
      }
      if (this.isBase64Image(imgUrl)) {
        resolve(imgUrl);
      } else {
        let img = document.createElement('img');
        img.crossOrigin = 'anonymous';
        img.onload = (x) => {
          let base64 = null;
          try {
            let image = x.srcElement as HTMLImageElement;
            base64 = this.getBase64Image(image);
            resolve(base64);
          } catch (e) {
            reject(e);
          }
        };
        img.src = this.imageSourceUrl(imgUrl);
      }
    });
  }

  private getBase64Image(img: HTMLImageElement, imageType = 'image/png') {
    let canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    let dataURL = canvas.toDataURL(imageType);
    return dataURL;
  }

  public isBase64Image(url) {
    return url.startsWith('data:');
  }

  public getImageSize(base64: string): Promise<{width, height}> {
    return new Promise(resolve => {
      const img = new Image;
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height
        });
      };
      img.onerror = (e) => {
        console.log(`error when getImageSize, ${e}`);
        resolve({
          width: 0,
          height: 0,
        });
      };
      img.src = base64;
    });
  }

  private compressImage(image, width = 1200) {
    let originWidth = image.naturalWidth,
      originHeight = image.naturalHeight;

    //let maxWidth = 960, maxHeight = 960;
    let maxWidth = width,
      maxHeight = width * 0.8;
    let targetWidth = originWidth,
      targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }
    return {
      targetWidth,
      targetHeight,
    };
  }

  private dataURLtoBlob(imgBase64) {
    let arr = imgBase64.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
  }

  private blobToFile(theBlob, fileName): File {
    theBlob.lastModifiedDate = new Date();
    theBlob.lastModified = new Date();
    theBlob.name = fileName;
    return theBlob;
  }
}
