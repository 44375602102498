import {
  TripAttraction,
  TripStorageCommand,
  UserTripModel,
} from './../models/vititor.trip.storage.command';
import { isEmptyArray } from 'src/app/common/utils/object.extensions';
import { EventTimeService } from './../../event-calendar/services/event.time.service';
import { VisitorEvent } from './../models/visitor.event';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable, share, shareReplay } from 'rxjs';
import { ApiService } from 'src/app/common/services/api/api.service';
import { IFrontendSettings } from '../ngrx.stores/frontend.settings/states';
import {
  CreateTripCommand,
  UserTrip,
} from '../models/vititor.trip.storage.command';
import { VisitorPlace } from '../models/visitor.place';
import { AIModel } from '../models/travel.assistant';

@Injectable({
  providedIn: 'root',
})
export class VisitorService {
  constructor(
    private apiService: ApiService,
    private timeService: EventTimeService
  ) {
  }

  public getFrontendSettings(hubName: string): Observable<IFrontendSettings> {
    return this.apiService.get(`visitors/${hubName}/all-frontend-settings`);
  }

  public getItemType(item: VisitorEvent | VisitorPlace): 'event' | 'place' {
    if (item.startTime) return 'event';
    return 'place';
  }

  public getImageBase64(imgUrl): Observable<any> {
    return this.apiService.get(`common/image-base64?url=${imgUrl}`);
  }

  public travelBuddyConversation(data) {
    return this.apiService.post(
      `visitors/${data.hubName}/travel-buddy-conversation`,
      data
    );
  }

  public getTravelBuddyConversationByUser(hubName: string, userId: string, from: string | Date): Observable<[{
    conversation: {ai, user}
  }]> {
    return this.apiService.get(`visitors/${hubName}/travel-buddy-conversation-by-user?&userId=${userId}&from=${from}`);
  }

  public sendAIQuery(
    hubName: string,
    feature: string,
    model: AIModel,
    query: string,
    history: Array<{type: string; msg: string}>,
    context?: string
  ): Observable<any> {
    return this.apiService.post(
      `visitors/${hubName}/ai-assistant`,
      {
        feature,
        query,
        model,
        history,
        context,
      },
      {timeout: 100 * 1000}
    );
  }

  public sendAIQueryStream(
    hubName: string,
    feature: string,
    provider: 'bard' | 'openAI',
    query: string,
    history: Array<{type: string; msg: string}>,
    callback: (data: string) => void
  ) {
    // return this.apiService.get(`visitors/${hubName}/ai-assistant`, {
    //   feature,
    //   query,
    //   provider,
    //   history,
    // });

    const es = new EventSource(
      `http://localhost:3000/api/visitors/${hubName}/ai-assistant`
    );
    es.onmessage = (e) => {
      const data = JSON.parse(e.data);

      if (data.choices[0].finish_reason === 'stop') {
        return es.close();
      }

      const {content = ''} = data.choices[0].delta;

      console.log(content);
      callback && callback(content);
    };
  }

  public getUserTrips(
    hubName: string,
    userFacebookId: string
  ): Promise<TripStorageCommand> {
    return lastValueFrom(
      this.apiService
        .get(`visitors/${hubName}/trips/user/${userFacebookId}`)
        .pipe(
          map((data: TripStorageCommand) => {
            if (!isEmptyArray(data.trips)) {
              data.trips.forEach((trip) => {
                let array = [];
                trip.attractions &&
                trip.attractions.forEach((x) => {
                  if (x.status.toLowerCase() === 'ok') {
                    if (x.feedType === 'event') {
                      this.timeService.processEventTimes(
                        x.item as VisitorEvent
                      );
                    }
                    array.push(x);
                  }
                });
                trip.attractions = array;
              });
            }
            return data;
          })
        )
    );
  }

  public addAttractionToTrip(
    hubName: string,
    command: {
      userFacebookId: string;
      tripId: string;
      attraction: TripAttraction;
    }
  ) {
    return this.apiService.put(
      `visitors/${hubName}/trips/add-attraction-v3`,
      command
    );
  }

  public createTrip(hubName: string, command: CreateTripCommand) {
    return this.apiService.post(`visitors/${hubName}/trips`, command);
  }

  public deleteTrip(hubName: string, userFacebookId: string, id: string) {
    return this.apiService.post(`visitors/${hubName}/trips/delete-trip`, {
      userFacebookId,
      id,
    });
  }

  public removeAttractionFromTrip(
    hubName: string,
    command: {
      feedId: string;
      userFacebookId: string;
      tripIndex: number;
    }
  ) {
    return this.apiService.put(
      `visitors/${hubName}/trips/remove-attraction`,
      command
    );
  }

  public ipTest(): Observable<any> {
    return this.apiService.get(`common/ipTest`);
  }

  getCityName(place: VisitorPlace | VisitorEvent) {
    const addressComponents = place.address.addressComponents || place['address_components'];
    if (addressComponents) {
      const level1 = addressComponents.find((x) => x.types.any((y) => y === 'administrative_area_level_1'));
      const locality = addressComponents.find((x) => x.types.any((y) => y === 'locality'));
      return level1 && locality ? `${locality.long_name}, ${level1.long_name}` : '';
    }
    return '';
  }

  public convertToUrlSafeString(inputString: string): string {
    // 1. Remove diacritics (accents and special characters)
    const stringWithoutDiacritics = inputString.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    // 2. Replace spaces with hyphens
    const stringWithHyphens = stringWithoutDiacritics.replace(/\s+/g, "-");
    // 3. Keep only alphanumeric characters, hyphens, and underscores
    const urlSafeString = stringWithHyphens.replace(/[^a-z0-9_-]/gi, "");
    // 4. Convert to lowercase (optional, but recommended for consistency)
    return urlSafeString.toLowerCase();
  }

  copyToClip(content: string) {
    let aux = document.createElement('input');
    aux.setAttribute('value', content);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');
    document.body.removeChild(aux);
    // alert('Link is copied to the clipboard');
  }

  getInquiryEmail(hubName: string): Observable<any> {
    return this.apiService.get(`visitors/${hubName}/inquiry-email`);
  }

  sendHelp(help): Observable<any> {
    return this.apiService.post(`common/send-help`, help);
  }
}
