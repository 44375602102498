<ng-container *ngIf="settings">
  <div *ngIf="mode==='dropdown'&&!settings.customSettings.customDefaultPlaceCategory.useDefault">
    <ig-nested-menu *ngIf="!isLoading" #customDefaultPlaceBtn [title]="title"
                    [items]="node?[node]:settings.customSettings.customDefaultPlaceCategory.customCategories"
                    (onClick)="onSelectCustomDefaultPlaceCategory($event)"
                    (onIconClick)="onSelectCustomDefaultPlaceCategory(null)"></ig-nested-menu>
  </div>

  <ig-accordion #accordion
                *ngIf="mode==='expand'||(mode==='dropdown'&&settings.customSettings.customDefaultPlaceCategory.useDefault)"
                (onIconClicked)="filterPlace('All')" [title]="title||'FILTER BY CATEGORY'" id="place-filter-category"
                [mode]="mode">

    <ng-container expand dropdown>
      <span mat-menu-item (click)="onSelectCustomDefaultPlaceCategory(null)">ALL</span>
    </ng-container>

    <ng-container *ngIf="settings.customSettings.customDefaultPlaceCategory.useDefault" dropdown>
      <ng-container *ngIf="settings.dataSource?.businessType==='normal'" dropdown>
        <div mat-menu-item *ngIf="settings.toggle.placeCategory.accommodation" (click)="filterPlace('Accommodations')"
             role="button" [class.is-selected]="isSelected('Accommodations')">
          Accommodations
        </div>

        <ng-container *ngIf="settings.toggle?.placeCategory.thingsToDo">
          <div mat-menu-item *ngIf="settings.toggle.placeCategory.dining" (click)="filterPlace('Dining & Nightlife')"
               role="button" [class.is-selected]="isSelected('Dining & Nightlife')">
            Dining & Nightlife
          </div>
          <div mat-menu-item *ngIf="settings.toggle.placeCategory.shopping"
               (click)="filterPlace('Shopping')" role="button" [class.is-selected]="isSelected('Shopping')">
            Shopping
          </div>

          <div mat-menu-item *ngIf="isShowThingsToDo" (click)="filterPlace('things-to-do')" role="button" tabindex="0"
               [class.is-selected]="isSelected('things-to-do')">Things to Do
          </div>
        </ng-container>
        <ng-container *ngIf="(settings.dataSource.place==='facebook')||!settings.toggle.placeCategory.thingsToDo">
          <div mat-menu-item *ngIf="settings.toggle.placeCategory.activity" (click)="filterPlace('Activities')"
               role="button" [class.is-selected]="isSelected('Activities')">
            Activities
          </div>
          <div mat-menu-item *ngIf="settings.toggle.placeCategory.art" (click)="filterPlace('Arts & Culture')"
               role="button" [class.is-selected]="isSelected('Arts & Culture')">
            Arts & Culture
          </div>
          <div mat-menu-item *ngIf="settings.toggle.placeCategory.dining" (click)="filterPlace('Dining & Nightlife')"
               role="button" [class.is-selected]="isSelected('Dining & Nightlife')">
            Dining & Nightlife
          </div>
          <!--          <div mat-menu-item *ngIf="settings.toggle.placeCategory.entertainment" (click)="filterPlace('Entertainment')"-->
          <!--               role="button" [class.is-selected]="isSelected('Entertainment')">-->
          <!--            Entertainment-->
          <!--          </div>-->
          <div mat-menu-item *ngIf="settings.toggle.placeCategory.sports" (click)="filterPlace('Sports')"
               role="button" [class.is-selected]="isSelected('Sports')">
            Sports
          </div>
          <div mat-menu-item *ngIf="settings.toggle.placeCategory.wellness" (click)="filterPlace('Health & Wellness')"
               role="button" [class.is-selected]="isSelected('Health & Wellness')">
            Health & Wellness
          </div>
          <div mat-menu-item *ngIf="settings.toggle.placeCategory.venue" (click)="filterPlace('Venue & Event Services')"
               role="button" [class.is-selected]="isSelected('Venue & Event Services')">
            Venue & Event Services
          </div>
          <div mat-menu-item *ngIf="settings.toggle.placeCategory.outdoor" (click)="filterPlace('Outdoors')"
               role="button" [class.is-selected]="isSelected('Outdoors & Fitness')">
            Outdoors
          </div>
          <!--          <div mat-menu-item *ngIf="settings.toggle.placeCategory.wellness" (click)="filterPlace('Wellness')"-->
          <!--               role="button" [class.is-selected]="isSelected('Wellness')">-->
          <!--            Wellness-->
          <!--          </div>-->
          <div mat-menu-item *ngIf="settings.toggle.placeCategory.shopping" (click)="filterPlace('Shopping')"
               role="button" [class.is-selected]="isSelected('Shopping')">
            Shopping
          </div>
        </ng-container>

        <div mat-menu-item *ngIf="settings.toggle.placeCategory.travel" (click)="filterPlace('Transportation')"
             role="button" [class.is-selected]="isSelected('Transportation')">
          Transportation
        </div>
        <div mat-menu-item *ngIf="settings.toggle.placeCategory.other" (click)="filterPlace('Other')" role="button"
             [class.is-selected]="isSelected('Other')">
          Other
        </div>
      </ng-container>

      <ng-container *ngIf="settings.dataSource.businessType==='area business'">
        <div mat-menu-item (click)="filterPlace('Beauty')" role="button" [class.is-selected]="isSelected('Beauty')">
          Beauty
        </div>
        <div mat-menu-item (click)="filterPlace('Cars & Motor Services')" role="button"
             [class.is-selected]="isSelected('Cars & Motor Services')">
          Cars & Motor Services
        </div>

        <!--        <div mat-menu-item (click)="filterPlace('Cleaning Services')" role="button"-->
        <!--             [class.is-selected]="isSelected('Cleaning Services')">-->
        <!--          Cleaning Services-->
        <!--        </div> -->
        <div mat-menu-item (click)="filterPlace('Services')" role="button"
             [class.is-selected]="isSelected('Services')">
          Services
        </div>
        <!--        <div mat-menu-item (click)="filterPlace('Pet Services')" role="button"-->
        <!--             [class.is-selected]="isSelected('Pet Services')">-->
        <!--          Pet Services-->
        <!--        </div>-->
        <div mat-menu-item (click)="filterPlace('Pet Care')" role="button"
             [class.is-selected]="isSelected('Pet Care')">
          Pet Care
        </div>
        <div mat-menu-item (click)="filterPlace('Convenience & Pharmacies')" role="button"
             [class.is-selected]="isSelected('Convenience & Pharmacies')">Convenience & Pharmacies
        </div>
        <div mat-menu-item (click)="filterPlace('Health')" role="button"
             [class.is-selected]="isSelected('Health')">Health
        </div>
      </ng-container>

      <ng-container *ngIf="settings.dataSource.businessType==='local secrets'">
        <div mat-menu-item (click)="filterPlace('Art and Culture')" role="button"
             [class.is-selected]="isSelected('Art and Culture')">Arts & Culture
        </div>
        <div mat-menu-item (click)="filterPlace('Food and Drink')" role="button"
             [class.is-selected]="isSelected('Food and Drink')">
          Food & Drink
        </div>

        <div mat-menu-item (click)="filterPlace('Nightlife')" role="button"
             [class.is-selected]="isSelected('Nightlife')">
          Nightlife
        </div>
        <div mat-menu-item (click)="filterPlace('Trip & Tours Planner')" role="button"
             [class.is-selected]="isSelected('Trip & Tours Planner')">Trip & Tours Planner
        </div>
        <div mat-menu-item (click)="filterPlace('Local Rental Services')" role="button"
             [class.is-selected]="isSelected('Local Rental Services')">Local Rental Services
        </div>
        <div mat-menu-item (click)="filterPlace('Health & Personal Care')" role="button"
             [class.is-selected]="isSelected('Health & Personal Care')">Health & Personal Care
        </div>
        <div mat-menu-item (click)="filterPlace('Accommodation')" role="button"
             [class.is-selected]="isSelected('Accommodation')">Accommodations
        </div>
        <div mat-menu-item (click)="filterPlace('Sports & Leisure')" role="button"
             [class.is-selected]="isSelected('Sports & Leisure')">Sports & Leisure
        </div>
        <div mat-menu-item (click)="filterPlace('Shoppings')" role="button"
             [class.is-selected]="isSelected('Shoppings')">
          Shopping
        </div>
      </ng-container>
    </ng-container>

    <ng-container expand
                  *ngIf="mode==='expand'&&settings.customSettings.customDefaultPlaceCategory&&!settings.customSettings.customDefaultPlaceCategory.useDefault">
      <div *ngFor="let item of settings.customSettings.customDefaultPlaceCategory.customCategories"
           class="custom-default-place-category-wrapper">
        <ig-nested-accordion-item [item]="item"
                                  (onClick)="selectCustomDefaultPlaceCategory($event)"></ig-nested-accordion-item>
      </div>
    </ng-container>

  </ig-accordion>
</ng-container>
